/* .css-wnqp0f-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
  margin-top: 15px;
}

.css-145gt01-MuiFormControl-root-MuiTextField-root {
  height: 40px;
  margin-top: 15px;
} */

/* globalStyles.css */
.hide-scrollbars {
  overflow: auto;
  height: 100vh;
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
.hide-scrollbars::-webkit-scrollbar {
  display: none;
}

/* Firefox */
.hide-scrollbars {
  scrollbar-width: none;
}

/* IE and Edge */
.hide-scrollbars {
  -ms-overflow-style: none;
}
